<template>
    <div>
        <v-container class="main-header py-0 px-sm-2 px-0 white" :class="{'isCheckout': isCheckout}">
            <v-layout align-center>
                <a href="https://www.zooliberec.cz/podpora/mecenas-chovu/" class="main-link">
                    <img :src="require('../../assets/logo_sirka.png')" class="custom-logo" :class="{'isCheckout': isCheckout}" itemprop="logo" v-if="$vuetify.breakpoint.smAndUp && !isCheckout" />
                    <img v-else :src="require('../../assets/logo_sirka.png')" class="mobile-custom-logo" />
                </a>
                <v-spacer></v-spacer>
                <v-btn href="https://www.zooliberec.cz/podpora/mecenas-chovu/" class="mr-2" color="primary" outlined tile height="48" v-if="$vuetify.breakpoint.smAndUp && !isCheckout">Návrat do katalogu zvířat</v-btn>
              <v-badge
                  v-if="!isCheckout"
                  :content="itemsInCart"
                  :value="itemsInCart"
                  color="primary"
                  overlap
                  class="main-cart align-center"
              >
                <v-btn color="primary" depressed tile height="48" @click="drawer = true" v-if="$vuetify.breakpoint.smAndUp">Nákupní košík</v-btn>
                <v-btn icon color="primary" v-if="$vuetify.breakpoint.xsOnly" @click="drawer = true">
                  <v-icon>mdi-cart</v-icon>
                </v-btn>
              </v-badge>
            </v-layout>
        </v-container>
        <v-navigation-drawer
                temporary
                v-model="drawer"
                :right="true"
                fixed
                width="340"
        >
            <cart-overlay @drawerClose="drawer = false" />
        </v-navigation-drawer>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import CartOverlay from '../Cart/CartOverlay'

    export default {
        name: 'MainHeader',
        props: ['isCheckout'],
        data () {
            return {
                drawer: false,
                menu: false
            }
        },
        components: {
            CartOverlay
        },
        computed: {
            ...mapGetters([
                'cartProducts'
            ]),
            itemsInCart() {
                let cart = this.cartProducts;
                if (!cart[0]) return null;
                return cart.reduce((accum, item) => accum + item.qty, 0)
            }
        }
    }
</script>

<style lang="scss">
    .main-header {
        .main-link {
            line-height: 0;
        }
        .main-cart {
            display: inline-flex;
            cursor: pointer;
        }
        .cart-title {
            display: inline-flex;
            margin-top: 5px;
            color: #FFFFFF;
        }
        .cart-image {
            cursor: pointer;
            width: 40px;
        }
        .mobileCart {
          max-width: 80px;
        }
        &.isCheckout {
            padding-top: 0;
            padding-bottom: 0;
        }
        .menu-item {
            a {
                padding: 0 1em;
                font-size: 16px;
                color: #FFFFFF !important;
                text-decoration: none;
                text-transform: uppercase;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        .main-title {
            color: #FFFFFF;
            font-weight: 300;
            text-transform: uppercase;
            margin-left: 40px;
            strong {
                display: block;
                font-weight: 700;
                font-size: 32px;
                line-height: 32px;
            }
        }
        .mobile-custom-logo {
            max-width: 140px;
        }
        .custom-logo {
            max-width: 180px;
            &.isCheckout {
                max-width: 150px;
            }
        }
        .menu-icon-link {
            border-radius: 7px !important;
        }
        .v-list, .v-menu__content {
            border-radius: 0 !important;
        }
        .v-menu__content {
            max-width: 100% !important;
            width: 100%;
            left: 0 !important;
        }
        .v-chip {
            .v-chip__content {
                cursor: pointer !important;
            }
        }
        .opening-hours {
            strong {
                display: block;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
</style>
