<template>
  <v-container fluid class="white--text important-links pa-0">
    <v-container>
      <v-row justify="space-between" dense>
        <v-col cols="6" sm="6">
          <strong class="mr-2"
            ><a
              href="/pdf/Zoo_Liberec_VOP_sponzoring_CZ.pdf"
              class="no-decoration white--text"
              target="_blank"
              >Obchodní podmínky</a
            ></strong
          >
        </v-col>
        <v-col cols="6" sm="6" class="text-right">
          <a
            href="https://www.facebook.com/zooliberec"
            class="no-decoration white--text"
            target="_blank"
            ><v-icon small color="white" class="mr-2">mdi-facebook</v-icon></a
          >
          <a
            href="https://www.zooliberec.cz"
            class="no-decoration white--text"
            target="_blank"
            ><v-icon small color="white" class="mr-1">mdi-earth</v-icon></a
          >
          <span
            ><a
              href="https://www.zooliberec.cz"
              class="no-decoration white--text"
              target="_blank"
              >www.zooliberec.cz</a
            ></span
          >
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "ImportantLinks",
};
</script>

<style lang="scss">
.no-decoration {
  text-decoration: none;
}
.important-links {
  background-color: #53332c;
  font-size: 12px;
  h4 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
  }
}
</style>
