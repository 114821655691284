<template>
  <div class="px-sm-0 px-2">
    <v-btn
      block
      :loading="loading"
      :disabled="
        loading || (!form.paymentMethodId && !form.shippingMethodId) || !valid
      "
      color="primary"
      depressed
      tile
      large
      @click="submit($event)"
      >Dokončit objednávku</v-btn
    >
    <p class="caption mt-2">
      Dokončením objednávky souhlasíte s uzavřením darovací smlouvy ve znění
      uvedeného v
      <a
        target="_blank"
        href="/pdf/Zoo_Liberec_VOP_sponzoring_CZ.pdf"
        @click.stop
        >Obchodních podmínkách</a
      >.
    </p>
    <v-checkbox
      v-model="form.customData.AnnualReportAgreement"
      label="Souhlasím s uveřejněním svého jména (cedule partnerů, web, výroční zpráva)."
    >
    </v-checkbox>
    <v-checkbox v-model="form.marketingAgreement" :rules="[rules.required]">
      <template v-slot:label>
        <div>
          Souhlasím se zpracováním osobních údajů pro marketingové účely Zoo
          Liberec
          <a
            target="_blank"
            href="/pdf/Zoo_Liberec_informovany_souhlas.pdf"
            @click.stop
          >
            zde
          </a>
        </div>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: "PlaceOrder",
  props: ["form", "loading", "valid"],
  data() {
    return {
      rules: window.validationRules.getRules(),
    };
  },
  methods: {
    submit(event) {
      this.$emit("submit", event);
    },
  },
};
</script>
