<template>
    <div>
      <v-container fluid class="grey lighten-4">
        <v-row>
          <v-col cols="12" class="text-center">
            <span class="display-1 font-weight-medium">{{ title }}</span>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12" sm="8" offset-sm="2" class="pt-0">
            <v-skeleton-loader type="image" v-if="loadingCategories"></v-skeleton-loader>
            <v-img :src="getProductImage(imageData)" v-else></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="8" offset-sm="2" class="text-center">
            {{ description }}
          </v-col>
        </v-row>
        <v-row align="center" align-content="center" class="align-center justify-center">
          <v-col cols="12" class="align-center justify-center text-center d-flex">
            <div class="d-inline-flex">
              <v-btn depressed min-width="36" width="36" tile color="primary" @click="decreaseQty()">
                <v-icon x-small>mdi-minus</v-icon>
              </v-btn>
            </div>
            <div class="mx-4 d-inline-flex flex-column align-center justify-center">
              <span>
                 {{ qty * amountWithVat }}
              </span>
              <span class="d-block caption">Kč</span>
            </div>
            <div class="d-inline-flex">
              <v-btn depressed min-width="36" width="36" tile color="primary" @click="increaseQty()">
                <v-icon x-small>mdi-plus</v-icon>
              </v-btn>
            </div>
            <div class="d-inline-flex">
              <v-btn class="ml-5" color="primary" height="48" x-large style="text-transform: lowercase" tile depressed @click="addToCart({product: currentProduct, qty: qty})">Přidat do košíku</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-16">
          <v-col cols="12" class="mb-10 mt-3 text-center">
            <v-btn outlined color="primary" large tile style="text-transform: lowercase" href="https://www.zooliberec.cz/podpora/bonus/" target="_blank">Informace o bonusech pro mecenáše</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
    import {getProductImage} from '@/Helper'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: 'ProductPage',
        metaInfo () {
            return {
                title: this.currentProduct ? this.currentProduct.name : '',
            }
        },
        mixins: [getProductImage],
      data () {
        return {
          qty: 1
        }
      },
        computed: {
            ...mapGetters([
                'allProducts',
                'currentProduct',
                'loadingCategories'
            ]),
            imageData() {
                if (this.currentProduct) {
                    return this.currentProduct.imageUrl;
                }

                return null;
            },
          title() {
            if (this.currentProduct) {
              return this.currentProduct.name;
            }

            return null;
          },
          description() {
            if (this.currentProduct) {
              return this.currentProduct.shortDescription;
            }

            return null;
          },
          amountWithVat() {
            if (this.currentProduct) {
              return this.currentProduct.amountWithVat;
            }

            return null;
          },
        },
        mounted() {
            let productAlias = this.$router.currentRoute.params;
            if (typeof productAlias.alias !== 'undefined') {
                this.changeProduct();
            }
        },
        methods: {
            ...mapActions([
                'changeProduct',
              'addToCart'
            ]),
          decreaseQty() {
              if (this.qty === 1) {
                return this.qty = 1;
              }

              this.qty = this.qty - 1;
          },
          increaseQty() {
            this.qty = this.qty + 1;
          }
        }
    }
</script>

