export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';
export const RETRIEVE_PRODUCTS = 'RETRIEVE_PRODUCTS';
export const RETRIEVE_CATEGORIES = 'RETRIEVE_CATEGORIES';
export const CHANGE_CATEGORY = 'CHANGE_CATEGORY';
export const CHANGE_PRODUCT = 'CHANGE_PRODUCT';
export const LOADING_CATEGORIES = 'LOADING_CATEGORIES';
export const RETRIEVE_USER = 'RETRIEVE_USER';
export const CHECKING_USER = 'CHECKING_USER';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const EMPTY_CART = 'EMPTY_CART';
export const USER_ADD_TO_CART = 'USER_ADD_TO_CART';
export const RETRIEVE_PAYMENT_METHODS = 'RETRIEVE_PAYMENT_METHODS';
export const PAYMENT_METHODS_LOADED = 'PAYMENT_METHODS_LOADED';
export const RETRIEVE_SHIPPING_METHODS = 'RETRIEVE_SHIPPING_METHODS';
export const SHIPPING_METHODS_LOADED = 'SHIPPING_METHODS_LOADED';
