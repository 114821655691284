<template>
  <div id="app">
    <v-app>
      <nav-bar :isCheckout="isCheckout"></nav-bar>
      <v-main class="body-background">
        <router-view></router-view>
      </v-main>
      <app-footer :isCheckout="isCheckout" />
      <snackbar />
      <cookie />
    </v-app>
  </div>
</template>

<script>
import Cookie from './components/System/Cookie.vue'
import NavBar from './components/System/NavBar.vue'
import Footer from './components/Footer/Footer'
import Snackbar from "./components/System/Snackbar";
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'app',
  metaInfo: {
    title: 'Zoo Liberec',
    titleTemplate: '%s | Zoo Liberec'
  },
  components: {
    NavBar,
    'AppFooter': Footer,
    Snackbar,
    Cookie
  },
  computed: {
    ...mapGetters([
      'allCategories'
    ])
  },
  data () {
    return {
      isCheckout: false
    }
  },
  mounted() {
    this.isCheckout = this.$router.currentRoute.name.includes('Checkout');
    if (!this.allCategories.length) {
      this.retrieveCategories();
    }
  },
  watch: {
    '$route.name': function (val) {
      this.isCheckout = val === 'Checkout';
    }
  },
  methods: {
    ...mapActions([
      'retrieveCategories'
    ])
  }
}
</script>

<style lang="scss">
@media (min-width: 1904px) {
  .container {
    max-width: 1185px;
    &.container--fluid {
      max-width: 100%;
    }
  }
}

.body-background {
  background-image: url('./assets/savana_uprava.svg');
  background-position: center bottom -2px;
}

.v-application {
  font-family: 'Josefin Sans', sans-serif !important;
  .body-2, .display-1 {
    font-family: 'Josefin Sans', sans-serif !important;
  }
}
</style>
