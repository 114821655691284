<template>
    <cookie-law theme="dark-lime">
            <v-layout slot-scope="props">
                <v-flex xs12 sm8>
                    Tento web a e-shop používá ke svému provozu, zlepšování funkčnosti a analýze návštěvnosti tzv. soubory cookies. Používáním tohoto webu a e-shopu s tím souhlasíte. Více informací naleznete <router-link to="/privacy" class="success-text">zde</router-link>.
                </v-flex>
                <v-flex xs12 sm4 text-right>
                        <v-btn color="primary" @click="props.accept">Rozumím</v-btn>
                </v-flex>
            </v-layout>
    </cookie-law>
</template>

<script>
    import CookieLaw from 'vue-cookie-law'

    export default {
        components: { CookieLaw }
    }
</script>

<style>
    a.success-text {
        color: #8BC34A !important;
    }
</style>