<template>
    <v-container grid-list-lg>

    </v-container>
</template>

<script>
    export default {
        name: 'Homepage',
        mounted() {
          window.location.replace('https://www.zooliberec.cz/podpora/mecenas-chovu/')
        }
    }
</script>
