import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'
import axios from 'axios'
import ValidationRules from './utilities/ValidationRules'

Vue.config.productionTip = false;

window.axios = axios;
window.validationRules = ValidationRules;

axios.interceptors.response.use(function (response) {
  // Do something with response data
  if (response.data.errors && response.data.errors.length) {
    return Promise.reject(response.data);
  }
  return response;
}, function (error) {
  // Do something with response error
  return Promise.reject(error.response);
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
