<template>
  <v-card flat>
    <v-card-title class="pb-0">
      <h3 class="checkout-section-title">Identifikace a platba</h3>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
              v-model="form.customer.firstName"
              label="Jméno"
              required
              :rules="[rules.required, customerNameLength]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
              v-model="form.customer.lastName"
              label="Příjmení"
              required
              :rules="[rules.required, customerNameLength]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
              v-model="form.customer.email"
              label="Email"
              required
              :rules="[rules.required, rules.email]"
              maxlength="50"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
              v-model="form.customer.phone"
              label="Telefon"
              hint="Nepovinné"
              persistent-hint
              maxlength="50"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
              v-model="form.customer.address.street"
              label="Ulice"
              required
              :rules="[rules.required]"
              maxlength="50"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
              v-model="form.customer.address.town"
              label="Město"
              required
              :rules="[rules.required]"
              maxlength="50"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
              v-model="form.customer.address.zip"
              label="PSČ"
              required
              :rules="[rules.required]"
              maxlength="10"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-checkbox
              class="mt-0"
              v-model="company"
              label="Nakupuji na firmu"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="company">
        <v-col cols="12" md="12">
          <v-text-field
              v-model="form.customer.companyName"
              label="Firma"
              required
              :rules="[rules.required]"
              maxlength="100"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="company">
        <v-col cols="12" md="6">
          <v-text-field
              v-model="form.customer.companyCRN"
              label="IČO"
              required
              :rules="[rules.required]"
              maxlength="20"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
              v-model="form.customer.companyVAT"
              label="DIČ"
              required
              maxlength="20"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-checkbox
              class="mt-0"
              v-model="gift"
              label="Vložit jméno obdarované osoby/firmy"
              hint="Bude vytištěno na certifikátu, pokud neuvedete, pak bude na certifikátu uvedeno vaše jméno."
              persistent-hint
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="gift">
        <v-col cols="12">
          <v-text-field
              v-model="form.customer.recipientName"
              label="Jméno obdarovaného"
              required
              :rules="[rules.required]"
              counter
              maxlength="50"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-overlay :value="checkingCustomer">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'DeliveryAddress',
  props: ['form'],
  data() {
    return {
      gift: false,
      company: false,
      disabled: true,
      rules: window.validationRules.getRules(),
      customerNameLength: () => ((this.form.customer.firstName.length + this.form.customer.lastName.length) <= 50) || 'Jméno a příjmení může mít dohromady maximálně 50 znaků'
    }
  },
  computed: {
    ...mapGetters([
      'checkingCustomer',
      'user'
    ]),
  },
  methods: {
    ...mapActions([
      'checkCustomerCard'
    ]),
    getCustomerByCard() {

      if (this.form.customer.couponCode && this.form.customer.email) {
        this.checkCustomerCard({coupon: this.form.customer.couponCode, email: this.form.customer.email});
      }
    }
  }
}
</script>
